<template>
  <v-layout id="app" class="rounded rounded-md">
    <!-- <v-snackbar v-if="mainStore.apiResponse.msg" :style="{ 'margin-top': '45px' }" :timeout="5000"
      v-model="mainStore.apiResponse.show" :color="mainStore.apiResponse.error ? 'error' : 'success'" variant="tonal"
      location="bottom right">
      <v-icon :color="mainStore.apiResponse.error ? 'error' : 'success'">{{
        mainStore.apiResponse.error
          ? 'mdi-close-circle-outline'
          : 'mdi-check-circle-outline'
        }}</v-icon>
      {{ mainStore.apiResponse.msg }}
    </v-snackbar> -->

    <Sidebar v-model="menuDrawer" />
    <TopMenu v-model="drawer" v-model:menuDrawer="menuDrawer"></TopMenu>
    <v-btn variant="text" color="primary" icon="mdi-account" @click.stop="drawer = !drawer"></v-btn>

    <v-navigation-drawer width="800" v-model="drawer" temporary location="right">
      <UserProfile />
    </v-navigation-drawer>

    <v-main class="maincontent mt-8" style="height: 100dvh;">
      <v-alert v-for="noti in notifications" :key="noti.text" variant="tonal" class="mx-4" :type="noti.type" :icon="noti.icon">
        {{ noti.text }}
      </v-alert>
      <v-overlay class="align-center justify-center" :model-value="mainStore.loading">
        <v-progress-circular indeterminate :color="mainStore.mainColor" />
      </v-overlay>



      <router-view v-if="!isLoading || !isAuthenticated" v-slot="{ Component }">

        <v-fade-transition>
          <keep-alive>
            <component :is="Component" />
          </keep-alive>
        </v-fade-transition>

      </router-view>
      <div v-else>Loading</div>


    </v-main>

  </v-layout>
</template>

<script setup>

import TopMenu from '@/layout/TopMenu.vue';
import { notificationsRef } from '@/firebase.js';
import Sidebar from '@/layout/Sidebar.vue';
import UserProfile from "@/components/elements/items/UserProfile";
import { useAuth0 } from "@auth0/auth0-vue";
import { useMainStore } from '@/stores/main'
const mainStore = useMainStore()
const { isLoading, isAuthenticated } = useAuth0();

import { useCollection } from 'vuefire'
import { ref } from 'vue'
let drawer = ref(false)
let menuDrawer = ref(true)
// let fakeNotification = ref(true)
let notifications = ref(useCollection(notificationsRef))

const setZoom = () => {
  if (window.matchMedia('(min-width: 780px)').matches) {
    // https://stackoverflow.com/questions/63387436/chart-js-mouseover-tooltips-appears-when-cursor-in-wrong-position
    document.body.style.zoom = "75%";
  } else {
    document.body.style.zoom = "100%";
  }
}
setZoom()


</script>

<style lang="scss" scoped>
@import '@/styles.scss';

main.v-main.maincontent {
  margin-left: -48px;
}

// main.v-main {
//   --v-layout-left: 215px !important;
//   --v-layout-top: 85px !important;
// }</style>
