import { useMainStore } from '@/stores/main'

// import { useAuth0 } from '@auth0/auth0-vue';
const getBaseUrl = () => {
  let environments = {
    production:
      "https://p5k8zvztq0.execute-api.eu-west-1.amazonaws.com/production",
    stage: "https://p1o2llvmvj.execute-api.eu-west-1.amazonaws.com/stage",
    development: `http://localhost:3000/dev`,
  };

  // return environments.production
  return environments[process.env.NODE_ENV] || environments.development;
};
const getAccessToken = async () => {
  try {
    const mainStore = useMainStore()
    let idToken = sessionStorage.getItem("idToken");
    if (idToken) {
      return Promise.resolve(idToken)
    }
    idToken = mainStore.idToken
    // let { idTokenClaims } = useAuth0()
    // idToken = idTokenClaims._rawValue.__raw;

    // sessionStorage.setItem("idToken", idToken);

    return Promise.resolve(idToken)

    // TODO: MAKE SOME AUTHENTICATED CHECKS

  } catch (error) {
    return Promise.reject(error)
  }


};



const fetchImplementation = async ({ options }) => {
  const token = await getAccessToken();
  let fetchOptions = {
    method: options.method,
    headers: {
      "Authorization": `Bearer ${token}`,
      'Content-Type': 'application/json',

    }
  }
  if (options.method.toLowerCase() === 'post') {
    fetchOptions.body = JSON.stringify(options.data)
  }

  const response = await fetch(options.url, fetchOptions);
  return response.json();
}

const doHttp = async ({ options }) => {
  const mainStore = useMainStore()
  try {

    let res = await fetchImplementation({ options })// base.request(options);
    if (options.showSucces) {
      mainStore.setAPIResponse({
        type: 'success',
        msg: options.succesMsg,
      })

    }
    return res;
  } catch (error) {
    console.log(error);

    mainStore.setAPIResponse({
      type: 'error',
      msg: `${options.name}: ${error}`,
    })

    if (error.response) {
      if (error.response.status === 401) {
        console.log('NO OK!!!');


      }

    }


    return Promise.reject("Could not do http");
  }
};


const getPayout = ({ adunitsForXpay }) => {
  if (!adunitsForXpay) {
    return 'Wrong input'
  }
  const options = {
    method: "POST",
    name: "payout",
    url: `${getBaseUrl()}/payout`,
    data: { adunitsForXpay },
  };

  return doHttp({ options });
};
const popupsForUnits = ({ units }) => {
  if (!units) {
    return 'Wrong input'
  }
  const options = {
    method: "POST",
    name: "popupsForUnits",
    url: `${getBaseUrl()}/pubopsForUnits`,
    data: { units },
  };

  return doHttp({ options });
};


// const bundleF = (options) => doHttp({ options });
// const debouceBundler = debounce(bundleF, 3000);
const bundlerPbjs = ({ site, version, modules, deployPrebidTo }) => {
  if (!version || !modules) {
    return 'Wring input'
  }
  const options = {
    method: "POST",
    name: "prebid",
    succesMsg: `Prebid file updated succesfully for ${site}`,
    showSucces: true,
    url: `${getBaseUrl()}/pbjs/bundle`,
    data: { version, modules, deployPrebidTo },


  };

  return doHttp({ options });
};
const getPbjsVerions = () => {

  const options = {
    method: "get",
    name: "pbjsverions",
    url: `${getBaseUrl()}/pbjs/versions`,
  };

  return doHttp({ options });
};



const getAnomalies = () => {

  const options = {
    method: "get",
    name: "anomalies",
    url: `${getBaseUrl()}/anomalies`,
  };

  return doHttp({ options });
};
const deleteAnomAlert = (site) => {

  const options = {
    method: "delete",
    name: "anomalies",
    url: `${getBaseUrl()}/anomalies/${site}`,
  };

  return doHttp({ options });
};
const getSiteStats = ({ site, daysBack, metric }) => {
  if (!site) return;

  const options = {
    method: "get",
    name: "metric",
    url: `${getBaseUrl()}/metrics/${site}/${metric}/${daysBack}`,
  };
  return doHttp({ options });
};
const getAbtests = () => {
  const options = {
    method: "get",
    name: "abtests",
    url: `${getBaseUrl()}/abtests`,
  };
  return doHttp({ options });
};
const getEmbeddableToken = ({ type, site }) => {
  if (!type) return;

  const options = {
    method: "GET",
    name: "dataframe",
    url: `${getBaseUrl()}/embeddable/${type}?site=${encodeURI(site)}`,
  };
  return doHttp({ options });
};

const getStatic = ({ siteName, start, end }) => {
  if (!siteName) return;

  const options = {
    method: "get",
    name: "Statistics",
    url: `${getBaseUrl()}/stats/${start}/${end}/${siteName}`,
  };
  return doHttp({ options });
}

const getPupOpportunities = () => {
  const options = {
    method: "get",
    name: "publisheropportunities",
    url: `${getBaseUrl()}/adpay/publisheropportunities`,
  };
  return doHttp({ options });
};



const getTagsForContainer = ({ containerId }) => {
  if (!containerId) return;

  const options = {
    method: "get",
    name: "Tags",
    url: `${getBaseUrl()}/getTags/${containerId}`,
  };
  return doHttp({ options });
};
const getTrackingStats = ({ name, timeBin, eventsFromLast, eventsFromLastUnit }) => {
  if (!name || !timeBin || !eventsFromLast) return;

  const options = {
    method: "get",
    name: "Tracking",
    url: `${getBaseUrl()}/tracking/${name}/${timeBin}/${eventsFromLast}/${eventsFromLastUnit}`,
  };
  return doHttp({ options });
};
// const getLiveTracking = ({ sitesFilter, lineItemFilter, eventsFromLast, after }) => {
//   if (!after || !eventsFromLast) return;

//   const options = {
//     method: "POST",
//     name: "Live",
//     succesMsg: "Sucess",
//     showSucces: true,
//     url: `${getBaseUrl()}/live/${after}/${eventsFromLast}`,
//     data: { sitesFilter, lineItemFilter, },

//   };
//   return doHttp({ options });
// };


const getTrackingStatsLast = ({ name, last, unit }) => {
  if (!name || !last || !unit) return;

  const options = {
    method: "get",
    name: "Tracking",
    url: `${getBaseUrl()}/tracking_last/${name}/${last}/${unit}`,
  };
  return doHttp({ options });
};
const getContainer = ({ containerId }) => {
  if (!containerId) return;

  const options = {
    name: "Container",
    method: "get",
    url: `${getBaseUrl()}/container/${containerId}`,
  };
  return doHttp({ options });
};
const getSite = ({ site, env = 'prod' }) => {
  if (!site) return;

  const options = {
    name: "Site",
    method: "get",
    url: `${getBaseUrl()}/site/${site}/${env}`,
  };
  return doHttp({ options });
};
const getTag = ({ containerId, id }) => {
  if (!containerId || !id) return;

  const options = {
    method: "get",
    name: "Tag",

    url: `${getBaseUrl()}/getTag/${containerId}/${id}`,
  };
  return doHttp({ options });
};
const getSelectorScripts = () => {
  const options = {
    method: "get",
    name: "selectorscripts",
    url: `${getBaseUrl()}/selectorscripts`,
  };
  return doHttp({ options });
};
const getSelectorScript = (key) => {
  const options = {
    method: "get",
    name: "selectorscripts",
    url: `${getBaseUrl()}/selectorscript/${key}`,
  };
  return doHttp({ options });
};
const saveSelectorScript = ({ key, body }) => {
  const options = {
    method: "post",
    name: "selectorscripts",

    url: `${getBaseUrl()}/selectorscript/${key}`,
    showSucces: true,
    succesMsg: "Selector script saved",

    data: { body },
  };
  return doHttp({ options });
};
const enrichAdunits = ({ domain, adnuntiusSiteId, codes }) => {
  const options = {
    method: "post",
    name: "Enrich",
    succesMsg: "Sucess",
    showSucces: true,
    url: `${getBaseUrl()}/enrichadunits`,
    data: { domain, adnuntiusSiteId, codes },
  };
  return doHttp({ options }) //.then((response) => ({ domain, ...response.data }));
};
const getNetworks = () => {
  const options = {
    method: "get",
    name: "networks",
    url: `${getBaseUrl()}/networks`,
  };
  return doHttp({ options });
};
const postNetworks = ({ networks }) => {
  const options = {
    method: "post",
    name: "networks",
    succesMsg: "Sucess",
    showSucces: true,
    url: `${getBaseUrl()}/networks`,
    data: { networks },
  };
  return doHttp({ options });
};
const getAuthSites = () => {
  const options = {
    method: "get",
    name: "Sites",
    url: `${getBaseUrl()}/sites`,
  };
  return doHttp({ options });
};
const getAuthUsers = () => {
  const options = {
    method: "get",
    name: "Users",
    url: `${getBaseUrl()}/users`,
  };
  return doHttp({ options });
};
const getAuthGroups = () => {
  const options = {
    method: "get",
    name: "Groups",
    url: `${getBaseUrl()}/groups`,
  };
  return doHttp({ options });
};
const getAdstxtGroups = () => {
  const options = {
    method: "get",
    name: "Adstxt",
    url: `${getBaseUrl()}/adstxtgroups`,
  };
  return doHttp({ options });
};
const getSizes = () => {
  const options = {
    method: "get",
    name: "Sizes",
    url: `${getBaseUrl()}/sizes`,
  };
  return doHttp({ options });
};
const getFormats = () => {
  const options = {
    method: "get",
    name: "Sizes",
    url: `${getBaseUrl()}/formats`,
  };
  return doHttp({ options });
};
const getSupplyPartners = ({ site = null }) => {

  const options = {
    method: "get",
    name: "supplypartner",
    url: `${getBaseUrl()}/supplypartner/${site}`,

  };
  return doHttp({ options });
};
const postSize = ({ size }) => {
  const options = {
    method: "post",
    name: "Size",
    succesMsg: "Succesfully saved your size",
    showSucces: true,
    url: `${getBaseUrl()}/sizes`,
    data: { size },
  };
  return doHttp({ options });
};
const postFormat = ({ format }) => {
  const options = {
    method: "post",
    name: "Format",
    succesMsg: "Succesfully saved your format",
    showSucces: true,
    url: `${getBaseUrl()}/formats`,
    data: { format },
  };
  return doHttp({ options });
};
const postAdstxtGroup = ({ group }) => {
  const options = {
    method: "post",
    name: "Adstxtgroups",
    url: `${getBaseUrl()}/adstxtgroups`,
    data: { group },
  };
  return doHttp({ options });
};
const writeToSupplyPartner = ({ supplyPartner }) => {

  const options = {
    method: "post",
    name: "supplypartner",
    succesMsg: "Succesfully created/updated supplypartner",
    showSucces: true,
    url: `${getBaseUrl()}/supplypartner/creation`,
    data: { supplyPartner },

  };
  return doHttp({ options });
};
const postUserSettings = ({ settings }) => {
  const options = {
    method: "post",
    name: "usersettings",
    succesMsg: "Sucess",
    showSucces: true,
    url: `${getBaseUrl()}/usersettings`,
    data: { settings },
  };
  return doHttp({ options });
};
const postGroup = ({ group }) => {
  const options = {
    method: "post",
    succesMsg: "Sucess",
    showSucces: true,
    name: "Groups",
    url: `${getBaseUrl()}/groups`,
    data: { group },
  };
  return doHttp({ options });
};
const postSite = ({ site }) => {
  const options = {
    method: "post",
    name: "sites",
    succesMsg: "Sucess",
    showSucces: true,
    url: `${getBaseUrl()}/sites`,
    data: { site },
  };
  return doHttp({ options });
};
const postAdsTxt = ({ site, recommendedtxt }) => {
  const options = {
    method: "post",
    name: "adstxt",
    succesMsg: "Sucess",
    showSucces: true,
    url: `${getBaseUrl()}/adstxtsite`,
    data: { site, recommendedtxt },
  };
  return doHttp({ options });
};
const adstxt = ({ site }) => {
  const options = {
    method: "get",
    name: "adstxt",
    url: `${getBaseUrl()}/adstxt/${site}`,
  };
  return doHttp({ options });
};
const postUser = ({ user }) => {
  const options = {
    method: "post",
    name: "Users",
    succesMsg: "Sucess",
    showSucces: true,
    url: `${getBaseUrl()}/users`,
    data: { user },
  };
  return doHttp({ options });
};
// const getSuggestions = () => {
//   const options = {
//     method: "get",
//     name: "suggestions",
//     url: `${getBaseUrl()}/suggestions`,
//   };
//   return doHttp({ options });
// };
const getIgnoredBids = ({ site }) => {
  const options = {
    method: "get",
    name: "ignorebids",
    url: `${getBaseUrl()}/ignorebids/${site}`,
  };
  return doHttp({ options });
};
const ignoredBids = ({ site, bidder, ignoreList }) => {
  const options = {
    method: "post",
    name: "ignorebids",
    succesMsg: "Succesfully saved your choice",
    showSucces: true,
    url: `${getBaseUrl()}/ignorebids/${site}`,
    data: { bidder, ignoreList },

  };
  return doHttp({ options });
};
const getDraft = ({ site }) => {
  const options = {
    method: "get",
    name: "Draft",
    url: `${getBaseUrl()}/draft/${site}`,
  };
  return doHttp({ options });
};

function debounce(func, delay) {
  let timer;
  return function (...args) {
    const context = this; // Keep the proper context
    clearTimeout(timer); // Clear the previous timer
    timer = setTimeout(() => func.apply(context, args), delay); // Set a new timer
  };
}
const saveDraftF = (options) => doHttp({ options });
const debouceSave = debounce(saveDraftF, 5000);

const saveDraft = ({ site, settings, deployer, draftName }) => {
  const options = {
    method: "post",
    name: "Draft",
    succesMsg: `${site} draft saved...`,
    showSucces: true,
    url: `${getBaseUrl()}/draft/${site}`,
    data: { settings, deployer, draftName },
  };
  // const debouceSave = debounce(saveDraftF, 5000);

  return debouceSave(options)
};
const deploy = ({
  site,
  adnuntiusId,
  context,
  settings,
  deployTo,
  deployer,
  customFeatures,
}) => {
  const options = {
    method: "post",
    name: "Deploy",
    succesMsg: "Deployment succesfull",
    showSucces: true,
    url: `${getBaseUrl()}/deploy/${site}`,
    data: {
      adnuntiusId,
      context,
      settings,
      deployTo,
      customFeatures,
      deployer,
    },
  };
  return doHttp({ options });
};
const createSite = ({ siteName, settings = null }) => {
  if (!siteName) return;

  const options = {
    method: "post",
    name: "Create",
    succesMsg: "New site created...",
    showSucces: true,
    url: `${getBaseUrl()}/createSite`,
    data: { siteName, settings },
  };
  return doHttp({ options });
};
const getDeployHistory = ({ site }) => {
  if (!site) return;
  const options = {
    method: "get",
    name: "History",
    url: `${getBaseUrl()}/containerHistory/${site}`,
  };
  return doHttp({ options });
};

const getPupUpp = ({ site }) => {
  if (!site) return;

  const options = {
    method: "get",
    name: "Pubopp",
    url: `${getBaseUrl()}/pubopp/${site}`,
  };
  return doHttp({ options });
};
const getAllPupUpp = () => {

  const options = {
    method: "get",
    name: "pubopps",
    url: `${getBaseUrl()}/pubopps`,
  };
  return doHttp({ options });
};

export {
  // getLiveTracking,
  getDeployHistory,
  getAllPupUpp,
  getPupUpp,
  deploy,
  createSite,
  getTagsForContainer,
  getTag,
  getContainer,
  getTrackingStats,
  getTrackingStatsLast,
  getSite,
  getStatic,
  enrichAdunits,
  postSite,
  getAuthSites,
  getAuthGroups,
  getAuthUsers,
  postGroup,
  postUser,
  postAdsTxt,
  getAdstxtGroups,
  postAdstxtGroup,
  getSiteStats,
  getAnomalies,
  getDraft,
  postUserSettings,
  deleteAnomAlert,
  
  saveDraft,
  postFormat,
  getFormats,
  adstxt,
  // getSuggestions,
  ignoredBids,
  getIgnoredBids,
  getSizes,
  postSize,
  getPupOpportunities,
  writeToSupplyPartner,
  getSupplyPartners,
  getEmbeddableToken,
  getSelectorScripts,
  saveSelectorScript,
  getSelectorScript,
  getAbtests,
  getNetworks,
  postNetworks,
  getPbjsVerions,
  bundlerPbjs,
  popupsForUnits,
  getPayout
};